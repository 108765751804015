import React, { useContext } from "react";

import { Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import jwt from "jwt-decode";
import { decodedToken } from "../State/AuthContext/types";
import { AuthContext } from "../State/AuthContext/AuthContext";
import { NotificationContext } from "../State/NotificationsContext/NotificationContext";
import { API_URL } from "../Settings";

const Login: React.FC = () => {
  const authObj = useContext(AuthContext);
  const setToken = authObj!.setToken;
  const [form] = Form.useForm();

  const notificationContext = useContext(NotificationContext);

  const invalidCredentialsNotification = () => {
    notificationContext.api.error({
      message: `Invalid Credentials`,
      placement: "topRight",
    });
  };

  async function submitLogin(credentials: {
    username: string;
    password: string;
  }) {
    axios
      .post(`${API_URL}/api/auth/token/`, credentials)
      .then((response) => {
        const accessToken = response.data.access;
        const refreshToken = response.data.refresh;
        const decodedToken: decodedToken = jwt(accessToken);
        const userId: number = decodedToken.user_id;
        localStorage.clear();

        if (userId === 1) {
          localStorage.setItem("user_type", "admin");
        } else {
          localStorage.setItem("user_type", "user");
        }
        localStorage.setItem("access_token", accessToken);
        setToken(accessToken);
        localStorage.setItem("refresh_token", refreshToken);
      })
      .catch(() => {
        invalidCredentialsNotification();
      });
  }

  return (
    <>
      <div
        style={{
          fontSize: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10%",
        }}
      >
        <div style={{ textAlign: "center", paddingBottom: 30 }}>
          <h1 style={{ color: "black" }}>Welcome to Traderion</h1>
          <h3 style={{ color: "black" }}>Please enter your credentials</h3>
        </div>
        <Form
          form={form}
          name="normal_login"
          // className="login-form"
          initialValues={{ username: "", password: "" }}
          onFinish={() => {
            const data = form.getFieldsValue() as {
              username: string;
              password: string;
            };
            submitLogin(data);
          }}
          // style={{ width: "100%" }}
          style={{ width: 300, textAlign: "center" }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input a your username.",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password." }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              // style={{ width: "30%" }}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Login;
