import UserHeader from "./UserHeader";
import { Navigate, Route, Routes } from "react-router-dom";
import BulkByYear from "./BulkByYear/BulkByYear";
import React from "react";
import TraineeSearch from "./TraineeSearch/TraineeSearch";
import { UserContext } from "../../State/UserState/UserContext";

export function UserIndex() {
  return (
    <>
      <UserHeader />
      <UserContext>
        <Routes>
          <Route path="/user/trainee_search" element={<TraineeSearch />} />
          <Route path="/user/bulk" element={<BulkByYear />} />
          <Route
            path="*"
            element={<Navigate to="/user/trainee_search" replace />}
          ></Route>
        </Routes>
      </UserContext>
    </>
  );
}
