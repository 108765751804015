export interface LogsType {
  key: number;
  id: string;
  reason: string;
  marca: string;
  email: string;
  nume: string;
  prenume: string;
  regiune: string;
  agentie: string;
  cod_CC: string;
  pozitie: string;
  an: string;
  tip_formare: string;
  track: string;
  denumire_track: string;
  progres: string;
  timp_online: string;
  status: string;
  activ: string;
  data_certificare: string;
  serie_certificat: string;
}

export const initialLogsState = [
  {
    key: 0,
    id: "",
    reason: "",
    marca: "",
    email: "",
    nume: "",
    prenume: "",
    regiune: "",
    agentie: "",
    cod_CC: "",
    pozitie: "",
    an: "",
    tip_formare: "",
    track: "",
    denumire_track: "",
    progres: "",
    timp_online: "",
    status: "",
    activ: "",
    data_certificare: "",
    serie_certificat: "",
  },
];
