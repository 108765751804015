import { createContext, Key, ReactElement, useState } from "react";
import { initialLogsState, LogsType } from "./TypesInitials&Columns";
// import { fetchLogs } from "./Api_requests";
import axios from "axios";
import { FormInstance } from "antd";
import { useForm } from "antd/es/form/Form";
import { API_URL } from "../../../Settings";

type logsContextType = {
  logs: LogsType[];
  setLogs: (logs: LogsType[]) => void;
  uploading: boolean;
  setUploading: (uploading: boolean) => void;
  progress: number;
  setProgress: (progress: number) => void;
  currentlyProcessed: string;
  setCurrentlyProcessed: (currentlyProcessed: string) => void;
  currentTablePage: number;
  setCurrentTablePage: (page: number) => void;
  scrollY: number;
  setScrollY: (scroll: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  editingKey: string;
  setEditingKey: (editingKey: string) => void;
  editForm: FormInstance | undefined;
  noOfEntries: number;
  setNoOfEntries: (no: number) => void;
  selected: Key[];
  setSelected: (selected: Key[]) => void;
  // setEditForm: (form: FormInstance | null) => void;
  fetchLogs: () => void;
};

export const DataTabContext = createContext<logsContextType>({
  logs: initialLogsState,
  setLogs: () => {},
  uploading: false,
  setUploading: () => {},
  progress: 0,
  setProgress: () => {},
  currentlyProcessed: "",
  setCurrentlyProcessed: () => {},
  currentTablePage: 1,
  setCurrentTablePage: () => {},
  scrollY: 0,
  setScrollY: () => {},
  pageSize: 10,
  setPageSize: () => {},
  editingKey: "",
  setEditingKey: () => {},
  editForm: undefined,
  noOfEntries: 0,
  setNoOfEntries: () => {},
  selected: [],
  setSelected: () => {},
  // setEditForm: () => {},
  fetchLogs: () => {},
});

export function DataTabContextWrapper(props: { children: ReactElement }) {
  const [form] = useForm();

  const [logs, setLogs_] = useState<LogsType[]>([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentlyProcessed, setCurrentlyProcessed] = useState("");
  const [noOfEntries, setNoOfEntries] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [scrollY, setScrollY] = useState(0);
  const [editingKey, setEditingKey] = useState("");

  const [selected, setSelected] = useState<Key[]>([]);

  // const [editForm] = useState<FormInstance>(form);
  const fetchNumberOfEntries = () => {
    axios.get(`${API_URL}/api/data/get_no_of_entries/`).then((response) => {
      setNoOfEntries(response.data);
    });
  };
  const setLogs = (logs: LogsType[]) => {
    let counter = 0;
    const logsWithKeys = logs.map((log: LogsType) => {
      counter += 1;
      return { ...log, key: counter };
    });
    setLogs_(logsWithKeys);
  };
  const fetchLogs = () => {
    return axios
      .get(`${API_URL}/api/data/get_logs/`)
      .then((response) => {
        setLogs(response.data);
        fetchNumberOfEntries();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <DataTabContext.Provider
      value={{
        logs: logs,
        setLogs: setLogs,
        uploading: uploading,
        setUploading: setUploading,
        progress: progress,
        setProgress: setProgress,
        currentlyProcessed: currentlyProcessed,
        setCurrentlyProcessed: setCurrentlyProcessed,
        currentTablePage: currentTablePage,
        setCurrentTablePage: setCurrentTablePage,
        scrollY: scrollY,
        setScrollY: setScrollY,
        pageSize: pageSize,
        setPageSize: setPageSize,
        editingKey: editingKey,
        setEditingKey: setEditingKey,
        editForm: form,
        noOfEntries: noOfEntries,
        setNoOfEntries: setNoOfEntries,
        selected: selected,
        setSelected: setSelected,
        // setEditForm: setEditForm,
        fetchLogs: fetchLogs,
      }}
    >
      {props.children}
    </DataTabContext.Provider>
  );
}
