import { Col, Row, Divider } from "antd";
import NewUserForm from "./NewUserForm";
import UsersTable from "./UsersTable";

export default function AccountsTab() {
  return (
    <>
      <Row>
        <Col style={{ minWidth: 520 }} flex={60}>
          <UsersTable />
        </Col>
        <Col flex={1}>
          <Divider style={{ height: "100%" }} type="vertical" />
        </Col>
        <Col flex={39}>
          <NewUserForm />
        </Col>
      </Row>
    </>
  );
}
