import { ColumnsType } from "antd/es/table";

export interface BulkType {
  Marca: string;
  Email: string;
  Nume: string;
  Prenume: string;
  Regiune: string;
  Agentie: string;
  Cod_CC: string;
  Pozitie: string;
  An: string;
  Tip_formare: string;
  Track: string;
  Denumire_track: string;
  Progres: string;
  Timp_online: string;
  Status: string;
  Activ: string;
  Data_certificare: string;
  Serie_certificat: string;
}
export const initialByYearRecords = [
  {
    Marca: "",
    Email: "",
    Nume: "",
    Prenume: "",
    Regiune: "",
    Agentie: "",
    Cod_CC: "",
    Pozitie: "",
    An: "",
    Tip_formare: "",
    Track: "",
    Denumire_track: "",
    Progres: "",
    Timp_online: "",
    Status: "",
    Activ: "",
    Data_certificare: "",
    Serie_certificat: "",
  },
];

export type byYearRecordsType = {
  Marca: string;
  Email: string;
  Nume: string;
  Prenume: string;
  Regiune: string;
  Agentie: string;
  Cod_CC: string;
  Pozitie: string;
  An: string;
  Tip_formare: string;
  Track: string;
  Denumire_track: string;
  Progres: string;
  Timp_online: string;
  Status: string;
  Activ: string;
  Data_certificare: string;
  Serie_certificat: string;
};

export const columnsByYearRecords: ColumnsType<BulkType> = [
  {
    title: "Marca",
    dataIndex: "marca",
    key: "Marca",
    width: 80,
    fixed: "left",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "Email",
    width: 220,
    fixed: "left",
  },
  {
    title: "Nume",
    dataIndex: "nume",
    key: "Nume",
    fixed: "left",
    width: 120,
  },
  {
    title: "Prenume",
    dataIndex: "prenume",
    key: "Prenume",
    fixed: "left",
    width: 160,
  },
  {
    title: "Regiune",
    dataIndex: "regiune",
    key: "Regiune",
    width: 100,
  },
  {
    title: "Agentie",
    dataIndex: "agentie",
    key: "Agentie",
    width: 150,
  },
  {
    title: "Cod_CC",
    dataIndex: "cod_cc",
    key: "Cod_CC",
    width: 90,
  },
  {
    title: "Pozitie",
    dataIndex: "pozitie",
    key: "Pozitie",
    width: 160,
  },
  {
    title: "An",
    dataIndex: "an",
    key: "An",
    width: 70,
  },
  {
    title: "Tip formare",
    dataIndex: "tip_formare",
    key: "Tip_formare",
    width: 140,
  },
  {
    title: "Track",
    dataIndex: "track",
    key: "Track",
    width: 90,
  },
  {
    title: "Denumire track",
    dataIndex: "denumire_track",
    key: "Denumire_track",
    width: 170,
  },
  {
    title: "Progres",
    dataIndex: "progres",
    key: "Progres",
    width: 80,
  },
  {
    title: "Timp online",
    dataIndex: "timp_online",
    key: "Timp_online",
    width: 90,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "Status",
    width: 110,
  },
  {
    title: "Activ",
    dataIndex: "activ",
    key: "Activ",
    width: 70,
  },
  {
    title: "Data certificare",
    dataIndex: "data_certificare",
    key: "Data_certificare",
    width: 150,
  },
  {
    title: "Serie certificat",
    dataIndex: "serie_certificat",
    key: "Serie_certificat",
    width: 110,
  },
  {
    title: "Then Marca",
    dataIndex: "then_marca",
    key: "then_marca",
    width: 100,
  },
];
