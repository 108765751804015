import { ReactElement, useEffect, useState } from "react";
import { BulkByYearContext } from "./BulkByYearTab/BulkByYearContext";
import { initialByYearRecords } from "./BulkByYearTab/Columns&Types&InitialState";
import { TraineeSearchContextWrapper } from "../SharedState/TraineeSearchTab/TraineeSearchContext";
import axios from "axios";
import { API_URL } from "../../Settings";

export function UserContext(props: { children: ReactElement }) {
  const [year, setYear] = useState(2018);
  const [byYearRecords, setByYearRecords] = useState(initialByYearRecords);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post(`${API_URL}/api/data/get_year/`, { year: year })
      .then((response) => {
        // console.log(response.data);
        setByYearRecords(response.data);
        setLoading(false);
        // const new_trainee = {
        //   marca: String(response.data["marca"]),
      })
      .catch((response) => {
        console.log(response.response.data);
      });
  }, [year]);

  const bulkByYearContextValue = {
    year: year,
    setYear: setYear,
    byYearRecords: byYearRecords,
    setByYearRecords: setByYearRecords,
    loading: loading,
    setLoading: setLoading,
  };

  return (
    <TraineeSearchContextWrapper>
      <BulkByYearContext.Provider value={bulkByYearContextValue}>
        {props.children}
      </BulkByYearContext.Provider>
    </TraineeSearchContextWrapper>
  );
}
