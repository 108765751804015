import { Menu, MenuProps, Row, Col, Affix } from "antd";
import { Link, useLocation } from "react-router-dom";
import Logout from "../../App/Logout";

const headerItems: MenuProps["items"] = [
  {
    label: <Link to={"/admin/accounts"}>Accounts</Link>,
    key: "/admin/accounts", // key has to be the path, otherwise selected item highlighting won't work
    // icon: <ReactWelcome />,
  },
  {
    label: <Link to={"/admin/data"}>Data</Link>,
    key: "/admin/data",
    // icon: <ReactWelcome />,
  },
  {
    label: <Link to={"/admin/trainee_search"}>Trainee Search</Link>,
    key: "/admin/trainee_search",
    // icon: <ReactWelcome />,
  },
  // {
  //   label: <Link to={"/admin/test"}>Test</Link>,
  //   key: "/admin/test",
  //   // icon: <Test />,
  // },
  // {
  //   label: <Link to={"/admin/accounts"}>Accounts</Link>,
  //   key: "/admin/accounts",
  //   // icon: <Test />,
  // },
  // {
  //   label: <Logout />,
  //   key: "logout",
  //   theme: "dark",
  // },
];
export default function AdminHeader() {
  const currentPath = useLocation().pathname;

  return (
    <Affix>
      <Row style={{ marginBottom: 30 }}>
        <Col span={22}>
          <Menu
            selectedKeys={[currentPath]}
            mode="horizontal"
            items={headerItems}
            theme="light"
          />
        </Col>
        <Col span={2}>
          <Menu
            mode="horizontal"
            items={[{ label: <Logout />, key: "logout" }]}
            theme="light"
          />
        </Col>
      </Row>
    </Affix>
  );
}
