import React, { useContext } from "react";
import { Table, Select, Typography, Row, Col, Button, Space } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { columnsByYearRecords } from "../../../State/UserState/BulkByYearTab/Columns&Types&InitialState";
import { BulkByYearContext } from "../../../State/UserState/BulkByYearTab/BulkByYearContext";
import "./test.css";

const BulkByYear: React.FC = () => {
  const context = useContext(BulkByYearContext);

  return (
    <div
      style={{
        width: "96%",
        marginLeft: "2%",
        boxShadow: "0 3px 24px 0 rgba(0, 0, 0, 0.05)",
      }}
    >
      <Table
        columns={columnsByYearRecords}
        dataSource={context.byYearRecords}
        pagination={{
          defaultPageSize: 10,
        }}
        scroll={{ x: 2900 }}
        bordered
        sticky
        loading={context.byYearRecords.length === 1}
        title={() => {
          return (
            <>
              <Row>
                <Col flex={98}>
                  <Typography.Title
                    level={4}
                    style={{ display: "inline-block", marginTop: 10 }}
                  >
                    Show all records of:
                  </Typography.Title>
                  <Space.Compact style={{ display: "inline-block" }}>
                    <Select
                      // onSelect={() => setLoading(true)}
                      defaultValue={context.year}
                      style={{ width: 90, marginLeft: 10 }}
                      // onChange={(value) => context.setYear(value)}
                      onChange={(value) => {
                        context.setYear(value);
                        context.setLoading(true);
                      }}
                      options={[
                        { value: "2018", label: "2018" },
                        { value: "2019", label: "2019" },
                        { value: "2020", label: "2020" },
                        { value: "2021", label: "2021" },
                        { value: "2022", label: "2022" },
                        { value: "2023", label: "2023" },
                      ]}
                    />
                    <Button
                      type="primary"
                      className={"no-hover"}
                      loading={context.loading}
                    >
                      {" "}
                    </Button>
                  </Space.Compact>
                </Col>
                <Col flex={2}>
                  <Button style={{ marginTop: 10 }} type={"primary"}>
                    {/*<Button>*/}
                    <CSVLink
                      filename={`logs.csv`}
                      data={context.byYearRecords}
                      className="btn btn-primary"
                    >
                      <DownloadOutlined style={{ marginRight: 10 }} />
                      Download
                    </CSVLink>
                  </Button>
                </Col>
              </Row>
            </>
          );
        }}
      ></Table>
    </div>
  );
};

export default BulkByYear;
