import { Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useContext } from "react";
import axios from "axios";
import {
  AccountType,
  UserType,
} from "../../../State/AdminState/AccountsTab/AccountsTabTypes";
import { AccountsContext } from "../../../State/AdminState/AccountsTab/accountsContext";
import { NotificationContext } from "../../../State/NotificationsContext/NotificationContext";
import { API_URL } from "../../../Settings";

const UsersTable: React.FC = () => {
  const notificationContext = useContext(NotificationContext);

  const context = useContext(AccountsContext);

  function successNotification(userData: UserType) {
    notificationContext.api.success({
      message: `User deleted!`,
      description: (
        <>
          Username: {userData.username} <br />
          Email: {userData.email} <br />
        </>
      ),
      placement: "topRight",
      duration: 0,
    });
  }

  function failureNotification() {
    notificationContext.api.error({
      message: `Error`,
      description: <>User not deleted!</>,
      placement: "topRight",
      duration: 0,
    });
  }

  function handleDelete(id: number) {
    axios
      .delete(`${API_URL}/api/users/delete_user/`, {
        data: { id: id },
      })
      .then((response) => {
        successNotification(response.data);

        const indexToDelete = context.accountsList.findIndex(
          (element) => element.id === id
        );
        context.accountsList.splice(indexToDelete, 1);
        // this map is done to create a new array. If old modified array is passed to setState function, it does not
        // notice a change, because you're passing the array's reference (which is the same); so a re-render is not triggered
        const newAccountsList = context.accountsList.map((element) => element);
        context.setAccountsList(newAccountsList);
      })
      .catch(() => {
        failureNotification();
      });
  }

  const columns: ColumnsType<AccountType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        if (record.id === 1) {
          return;
        }
        return (
          <Space size="middle">
            <a
              onClick={() => {
                handleDelete(record.id);
              }}
            >
              Delete
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        style={{
          width: "70%",
          marginLeft: "15%",
          minWidth: 420,
          boxShadow: "0 3px 24px 0 rgba(0, 0, 0, 0.03)",
        }}
        columns={columns}
        dataSource={context.accountsList}
        title={() => {
          return (
            <Typography.Title
              style={{ marginTop: 0, marginBottom: 0 }}
              level={5}
            >
              Accounts:
            </Typography.Title>
          );
        }}
        footer={() => ""}
        bordered
      />
    </>
  );
};

export default UsersTable;
