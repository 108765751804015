import { Form, Input, Button, Card } from "antd";
import axios from "axios";
import React, { useContext } from "react";
import {
  initialNewUser,
  UserType,
} from "../../../State/AdminState/AccountsTab/AccountsTabTypes";
import { AccountsContext } from "../../../State/AdminState/AccountsTab/accountsContext";
import { NotificationContext } from "../../../State/NotificationsContext/NotificationContext";
import { API_URL } from "../../../Settings";

const NewUserForm: React.FC = () => {
  const notificationContext = useContext(NotificationContext);

  const [form] = Form.useForm();

  const context = useContext(AccountsContext);
  form.setFieldsValue(context.newUser);
  function successNotification(userData: UserType, password: string) {
    notificationContext.api.success({
      message: `User created!`,
      description: (
        <>
          Username: {userData.username} <br />
          Email: {userData.email} <br />
          Password: {password} <br />
        </>
      ),
      placement: "topRight",
      duration: 0,
    });
  }
  function failureNotification(err_message: string) {
    notificationContext.api.error({
      message: `User creation failed!`,
      description: err_message,
      placement: "topRight",
      duration: 0,
    });
  }
  async function onFormSubmit(newUserData: UserType) {
    axios
      .post(`${API_URL}/api/users/add_user/`, newUserData)
      .then((response) => {
        const newUser = { ...response.data, key: response.data.id };
        successNotification(response.data, newUserData.password);
        const newAccountsList = context.accountsList.map((element) => element);
        newAccountsList.push(newUser);
        context.setAccountsList(newAccountsList);
        context.setNewUser(initialNewUser);
        form.resetFields();
      })
      .catch((response) => {
        console.log(response);
        failureNotification(response.response.data.err_message);
      });
  }
  return (
    <>
      <Card
        title={"Add a new user:"}
        style={{
          width: "60%",
          marginLeft: "20%",
          minWidth: 300,
          boxShadow: "0 3px 24px 0 rgba(0, 0, 0, 0.03)",
        }}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFormSubmit}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              { required: true, message: "Please input user's username." },
            ]}
          >
            <Input
              onChange={(event) => {
                context.setNewUser({
                  ...context.newUser,
                  username: event.target.value,
                });
              }}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input user's email." }]}
          >
            <Input
              onChange={(event) => {
                context.setNewUser({
                  ...context.newUser,
                  email: event.target.value,
                });
              }}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input user's password." },
            ]}
          >
            <Input
              onChange={(event) => {
                context.setNewUser({
                  ...context.newUser,
                  password: event.target.value,
                });
              }}
              allowClear
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default NewUserForm;
