import { createContext } from "react";
import { AccountType, initialNewUser, UserType } from "./AccountsTabTypes";

type logsContextType = {
  newUser: UserType;
  setNewUser: (newUser: UserType) => void;
  accountsList: AccountType[];
  setAccountsList: (accountsList: AccountType[]) => void;
  // setLogs: React.Dispatch<React.SetStateAction<LogsType>>;
};

export const AccountsContext = createContext<logsContextType>({
  newUser: initialNewUser,
  setNewUser: () => {},
  accountsList: [],
  setAccountsList: () => {},
});
