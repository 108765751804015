import { createContext } from "react";
import {
  byYearRecordsType,
  initialByYearRecords,
} from "./Columns&Types&InitialState";

type bulkByYearContextType = {
  year: number;
  setYear: (year: number) => void;
  byYearRecords: byYearRecordsType[];
  setByYearRecords: (records: byYearRecordsType[]) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export const BulkByYearContext = createContext<bulkByYearContextType>({
  year: 2018,
  setYear: () => {},
  byYearRecords: initialByYearRecords,
  setByYearRecords: () => {},
  loading: true,
  setLoading: () => {},
});
