import { createContext, ReactElement, useState } from "react";
import {
  traineeInitialState,
  TraineeType,
  trainingsInitialState,
  TrainingsType,
} from "./Columns&Types&InitialState";

type traineeSearchContextType = {
  searchBy: string;
  setSearchBy: (searchBy: string) => void;
  byMarca: {
    searchTerm: string;
    setSearchTerm: (marca: string) => void;
    trainee: TraineeType;
    setTrainee: (trainee: TraineeType) => void;
    trainings: TrainingsType[];
    setTrainings: (trainings: TrainingsType[]) => void;
  };
  byEmail: {
    searchTerm: string;
    setSearchTerm: (email: string) => void;
    trainee: TraineeType;
    setTrainee: (trainee: TraineeType) => void;
    trainings: TrainingsType[];
    setTrainings: (trainings: TrainingsType[]) => void;
  };
  byName: {
    searchTerm: { nume: string; prenume: string };
    setSearchTerm: (fullName: { nume: string; prenume: string }) => void;
    trainee: TraineeType;
    setTrainee: (trainee: TraineeType) => void;
    trainings: TrainingsType[];
    setTrainings: (trainings: TrainingsType[]) => void;
  };
};

export const TraineeSearchContext = createContext<traineeSearchContextType>({
  searchBy: "marca",
  setSearchBy: () => {},
  byMarca: {
    searchTerm: "",
    setSearchTerm: () => {},
    trainee: traineeInitialState,
    setTrainee: () => {},
    trainings: trainingsInitialState,
    setTrainings: () => {},
  },
  byEmail: {
    searchTerm: "",
    setSearchTerm: () => {},
    trainee: traineeInitialState,
    setTrainee: () => {},
    trainings: trainingsInitialState,
    setTrainings: () => {},
  },
  byName: {
    searchTerm: { nume: "", prenume: "" },
    setSearchTerm: () => {},
    trainee: traineeInitialState,
    setTrainee: () => {},
    trainings: trainingsInitialState,
    setTrainings: () => {},
  },
});

// ====================================================

export function TraineeSearchContextWrapper(props: { children: ReactElement }) {
  const [traineeByMarca, setTraineeByMarca] = useState(traineeInitialState);
  const [traineeByEmail, setTraineeByEmail] = useState(traineeInitialState);
  const [traineeByName, setTraineeByName] = useState(traineeInitialState);
  const [trainingsByMarca, setTrainingsByMarca] = useState(
    trainingsInitialState
  );
  const [trainingsByEmail, setTrainingsByEmail] = useState(
    trainingsInitialState
  );
  const [trainingsByName, setTrainingsByName] = useState(trainingsInitialState);
  const [searchTermByMarca, setSearchTermByMarca] = useState("");
  const [searchTermByEmail, setSearchTermByEmail] = useState("");
  const [searchTermByName, setSearchTermByName] = useState({
    nume: "",
    prenume: "",
  });
  const [searchBy, setSearchBy] = useState("marca");

  const traineeContextValue = {
    searchBy: searchBy,
    setSearchBy: setSearchBy,
    byMarca: {
      searchTerm: searchTermByMarca,
      setSearchTerm: setSearchTermByMarca,
      trainee: traineeByMarca,
      setTrainee: setTraineeByMarca,
      trainings: trainingsByMarca,
      setTrainings: setTrainingsByMarca,
    },
    byEmail: {
      searchTerm: searchTermByEmail,
      setSearchTerm: setSearchTermByEmail,
      trainee: traineeByEmail,
      setTrainee: setTraineeByEmail,
      trainings: trainingsByEmail,
      setTrainings: setTrainingsByEmail,
    },
    byName: {
      searchTerm: searchTermByName,
      setSearchTerm: setSearchTermByName,
      trainee: traineeByName,
      setTrainee: setTraineeByName,
      trainings: trainingsByName,
      setTrainings: setTrainingsByName,
    },
  };

  return (
    <TraineeSearchContext.Provider value={traineeContextValue}>
      {props.children}
    </TraineeSearchContext.Provider>
  );
}
