import UploadComponent from "./UploadComponent";
import LogsTable from "./LogsTable";
// import { useContext } from "react";
// import { DataTabContext } from "../../../State/AdminState/DataTab/DataTabContext";

export default function DataTab() {
  // const logsContext = useContext(DataTabContext);

  return (
    <>
      <div style={{ width: "70%", marginLeft: "15%" }}>
        <UploadComponent />
      </div>

      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          marginTop: 35,
          boxShadow: "0 3px 24px 0 rgba(0, 0, 0, 0.05)",
        }}
      >
        {/*{logsContext.logs[0]["Reason"] != "" && <LogsTable />}*/}
        <LogsTable />
      </div>
    </>
  );
}
