import { createContext } from "react";
import { NotificationInstance } from "antd/es/notification/interface";

type NotificationContextType = {
  api: NotificationInstance;
  // contextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  // contextHolder: React.ReactElement | undefined;
};

export const NotificationContext = createContext<NotificationContextType>({
  api: {
    success: () => {},
    error: () => {},
    info: () => {},
    warning: () => {},
    open: () => {},
    destroy: () => {},
  },
  // contextHolder: undefined,
});
