import Login from "./Login";
import React, { useContext } from "react";
import { AuthContext } from "../State/AuthContext/AuthContext";
import { AdminIndex } from "../views/AdminViews/AdminIndex";
import { UserIndex } from "../views/UserViews/UserIndex";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminContext } from "../State/AdminState/AdminContext";

export default function Router() {
  const authObj = useContext(AuthContext);
  const token = authObj!.token;
  const user_type = localStorage.getItem("user_type");

  if (!token) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="login" replace />}></Route>
      </Routes>
    );
  }

  if (user_type === "admin") {
    return (
      <AdminContext>
        <AdminIndex />
      </AdminContext>
    );
  }

  return <UserIndex />;
}
