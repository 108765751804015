import { Menu, MenuProps, Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";
import Logout from "../../App/Logout";

const headerItems: MenuProps["items"] = [
  {
    label: <Link to={"/user/trainee_search"}>Trainee search</Link>,
    key: "/user/trainee_search", // key has to be the path, otherwise selected item highlighting won't work
    // icon: <ReactWelcome />,
  },
  {
    label: <Link to={"/user/bulk"}>Bulk by year</Link>,
    key: "/user/bulk", // key has to be the path, otherwise selected item highlighting won't work
    // icon: <ReactWelcome />,
  },
  // {
  //   label: <Link to={"/user/tab2"}>Tab2</Link>,
  //   key: "/user/tab2",
  //   // icon: <ReactWelcome />,
  // },
  // {
  //   label: <Link to={"/user/tab3"}>Tab3</Link>,
  //   key: "/user/tab3",
  //   // icon: <Test />,
  // },
  // {
  //   label: <Link to={"/user/tab4"}>Tab4</Link>,
  //   key: "/user/tab4",
  //   // icon: <Test />,
  // },
];
export default function UserHeader() {
  const currentPath = useLocation().pathname;

  return (
    // <Affix>
    <Row style={{ marginBottom: 30 }}>
      <Col span={22}>
        <Menu
          selectedKeys={[currentPath]}
          mode="horizontal"
          items={headerItems}
          theme="light"
        />
      </Col>
      <Col span={2}>
        <Menu
          mode="horizontal"
          items={[{ label: <Logout />, key: "logout" }]}
          theme="light"
        />
      </Col>
    </Row>
    // </Affix>
  );
}
