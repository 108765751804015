import { useContext } from "react";
import { AuthContext } from "../State/AuthContext/AuthContext";

export default function Logout() {
  const setToken = useContext(AuthContext)!.setToken;
  function handleLogout() {
    localStorage.clear();
    setToken("");
  }
  return <a onClick={handleLogout}>Logout</a>;
}
