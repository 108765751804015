import { ReactElement, useEffect, useState } from "react";
import { DataTabContextWrapper } from "./DataTab/DataTabContext";
import { AccountsContext } from "./AccountsTab/accountsContext";
import { AccountType, initialNewUser } from "./AccountsTab/AccountsTabTypes";
import axios from "axios";
import { TraineeSearchContextWrapper } from "../SharedState/TraineeSearchTab/TraineeSearchContext";
import { API_URL } from "../../Settings";

export function AdminContext(props: { children: ReactElement }) {
  const [newUser, setNewUser] = useState(initialNewUser);
  const [accountsList, setAccountsList] = useState<AccountType[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/users/get_users/`)
      .then((response) => {
        const AccountsList = response.data.map(
          (userObj: { id: string; username: string; email: string }) => {
            return { ...userObj, key: userObj.id };
          }
        );
        setAccountsList(AccountsList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DataTabContextWrapper>
      <AccountsContext.Provider
        value={{
          newUser: newUser,
          setNewUser: setNewUser,
          accountsList: accountsList,
          setAccountsList: setAccountsList,
        }}
      >
        <TraineeSearchContextWrapper>
          {props.children}
        </TraineeSearchContextWrapper>
      </AccountsContext.Provider>
    </DataTabContextWrapper>
  );
}
