export type UserType = {
  username: string;
  email: string;
  password: string;
};

export type AccountType = {
  id: number;
  username: string;
  email: string;
  key: string;
};

export const initialNewUser = {
  username: "",
  email: "",
  password: "",
};
