import React, { Key, useContext, useEffect } from "react";
import {
  Button,
  Col,
  FormInstance,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import { Form, Input } from "antd";
import { DataTabContext } from "../../../State/AdminState/DataTab/DataTabContext";
import { CSVLink } from "react-csv";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { LogsType } from "../../../State/AdminState/DataTab/TypesInitials&Columns";
import axios from "axios";
import {
  TraineeType,
  TrainingsType,
} from "../../../State/SharedState/TraineeSearchTab/Columns&Types&InitialState";
import { NotificationContext } from "../../../State/NotificationsContext/NotificationContext";
import { API_URL } from "../../../Settings";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  required: boolean;
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: LogsType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  required,
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const LogsTable: React.FC = () => {
  const logsContext = useContext(DataTabContext);
  const notificationContext = useContext(NotificationContext);

  // this is done like this bc. logs.array would give you a bigger number. The list is not only populated by logs.
  let number_of_logs = 0;
  logsContext.logs.forEach((log) => {
    if (log.id != undefined) {
      number_of_logs += 1;
    }
  });

  const setScroll = () => {
    logsContext.setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", setScroll);
    window.scrollTo(0, logsContext.scrollY);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const isEditing = (record: LogsType) => record.id === logsContext.editingKey;
  const isReference = (record: LogsType) => !("id" in record);
  const edit = (record: LogsType) => {
    // form.setFieldsValue({ name: "", age: "", address: "", ...record });
    if (logsContext.editForm !== undefined) {
      logsContext.editForm.setFieldsValue({ ...record });
    }
    logsContext.setEditingKey(record.id);
  };
  const cancel = () => {
    logsContext.setEditingKey("");
  };
  const successProcessNotification = (data: {
    trainee: TraineeType;
    training: TrainingsType;
  }) => {
    notificationContext.api.success({
      message: "Entry added successfully! ",
      description: (
        <>
          <p style={{ display: "block", margin: 0 }}>
            Marca: {data.trainee.marca}
          </p>
          <p style={{ display: "block", margin: 0 }}>
            Nume: {data.trainee.nume}
          </p>
          <p style={{ display: "block", margin: 0 }}>
            Prenume: {data.trainee.prenume}
          </p>
          <p style={{ display: "block", margin: 0 }}>
            Email: {data.trainee.email}
          </p>
          <br />
          <p style={{ display: "block", margin: 0 }}>
            Track: {data.training.track}
          </p>
          <p style={{ display: "block", margin: 0 }}>An: {data.training.an}</p>
        </>
      ),
      duration: 0,
    });
  };
  const failedProcessNotification = (log: string) => {
    notificationContext.api.error({
      message: "Invalid Entry",
      description: log,
      duration: 0,
    });
  };
  const process = (newrecord: FormInstance | undefined) => {
    let formData;
    if (newrecord !== undefined) {
      formData = newrecord.getFieldsValue(true);
    }
    logsContext.setEditingKey("");
    axios
      .post(`${API_URL}/api/data/update_log/`, formData)
      .then((response) => {
        successProcessNotification(response.data);
        logsContext.fetchLogs();
      })
      .catch((response) => {
        failedProcessNotification(response.response.data["log"]);
        logsContext.fetchLogs();
      });
  };

  const forceSave = (record: FormInstance | undefined) => {
    let formData;
    if (record !== undefined) {
      formData = record.getFieldsValue(true);
    }
    axios
      .post(`${API_URL}/api/data/force_save/`, formData)
      .then((response) => {
        successProcessNotification(response.data);
        logsContext.fetchLogs();
      })
      .catch((response) => {
        failedProcessNotification(response.response.data);
        logsContext.fetchLogs();
      });
    logsContext.setEditingKey("");
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "operation",
      width: 110,
      fixed: true,
      render: (_: any, record: LogsType) => {
        const editable = isEditing(record);
        const buttons = editable ? (
          <span style={{ alignItems: "center" }}>
            <Popconfirm
              title="Are you sure? Force saving bypasses all checks and comparisons. Use only to save people that actually have the same name."
              onConfirm={() => forceSave(logsContext.editForm)}
            >
              <Typography.Link type={"danger"} style={{ display: "block" }}>
                Force save
              </Typography.Link>
            </Popconfirm>
            <Typography.Link
              // onClick={() => save(record.key)}
              onClick={() => process(logsContext.editForm)}
              style={{ display: "block" }}
            >
              Process
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              style={{ display: "block" }}
              disabled={logsContext.editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title={"Are you sure you want to delete this unsaved entry?"}
              onConfirm={() => handleDeleteLog(record)}
            >
              <Typography.Link
                // style={{ marginRight: 8 }}
                disabled={logsContext.editingKey !== ""}
              >
                Delete
              </Typography.Link>
            </Popconfirm>
          </span>
        );
        const content = isReference(record) ? (
          <Typography.Text type="secondary">Reference</Typography.Text>
        ) : (
          buttons
        );
        return logsContext.selected.length > 1 ? <p></p> : content;
      },
    },
    {
      title: "Log id",
      dataIndex: "id",
      // key: "id",
      width: 80,
      fixed: true,
      editable: false,
      required: false,
    },
    {
      title: "Log",
      dataIndex: "reason",
      // key: "Log",
      // width: 460,
      width: 300,
      fixed: true,
      editable: false,
      required: false,
    },
    {
      title: "Marca",
      dataIndex: "marca",
      // key: "Marca",
      width: 95,
      fixed: false,
      editable: true,
      required: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      // key: "Email",
      width: 200,
      editable: true,
      fixed: false,
      required: true,
    },
    {
      title: "Nume",
      dataIndex: "nume",
      // key: "Nume",
      editable: true,
      fixed: false,
      required: true,
    },
    {
      title: "Prenume",
      dataIndex: "prenume",
      // key: "Prenume",
      editable: true,
      fixed: false,
      required: true,
    },
    {
      title: "Regiune",
      dataIndex: "regiune",
      // key: "Regiune",
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Agentie",
      dataIndex: "agentie",
      // key: "Agentie",
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Cod_CC",
      dataIndex: "cod_cc",
      // key: "Cod_CC",
      width: 90,
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Pozitie",
      dataIndex: "pozitie",
      // key: "Pozitie",
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "An",
      dataIndex: "an",
      // key: "An",
      width: 95,
      editable: true,
      fixed: false,
      required: true,
    },
    {
      title: "Tip_formare",
      dataIndex: "tip_formare",
      // key: "Tip_formare",
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Track",
      dataIndex: "track",
      // key: "Track",
      width: 105,
      editable: true,
      fixed: false,
      required: true,
    },
    {
      title: "Denumire_track",
      dataIndex: "denumire_track",
      // key: "Denumire_track",
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Progres",
      dataIndex: "progres",
      // key: "Progres",
      width: 90,
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Timp online",
      dataIndex: "timp_online",
      // key: "Timp_online",
      width: 80,
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      // key: "Status",
      width: 110,
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Activ",
      dataIndex: "activ",
      // key: "Activ",
      width: 70,
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Data_certificare",
      dataIndex: "data_certificare",
      // key: "Data_certificare",
      width: 150,
      editable: true,
      fixed: false,
      required: false,
    },
    {
      title: "Serie_certificat",
      dataIndex: "serie_certificat",
      // key: "Serie_certificat",
      width: 140,
      editable: true,
      fixed: false,
      required: false,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: LogsType) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        isReference: isReference(record),
        required: col.required,
      }),
    };
  });
  const successDeleteLogNotification = (record: LogsType) => {
    console.log(record);
    notificationContext.api.success({
      message: "Deleted",
      description: (
        <>
          <p style={{ display: "block", margin: 0 }}>Marca: {record.marca}</p>
          <p style={{ display: "block", margin: 0 }}>Nume: {record.nume}</p>
          <p style={{ display: "block", margin: 0 }}>
            Prenume: {record.prenume}
          </p>
          <p style={{ display: "block", margin: 0 }}>Email: {record.email}</p>
          <br />
          <p style={{ display: "block", margin: 0 }}>Track: {record.track}</p>
          <p style={{ display: "block", margin: 0 }}>An: {record.an}</p>
        </>
      ),
      duration: 0,
    });
  };
  const handleDeleteLog = (record: LogsType) => {
    axios
      .post(`${API_URL}/api/data/delete_log/`, { log_id: record.id })
      .then(() => {
        successDeleteLogNotification(record);
        logsContext.fetchLogs();
      })
      .catch(() => {});
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    logsContext.setSelected(newSelectedRowKeys);
  };
  const selectedRowKeys = logsContext.selected;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: LogsType) => ({
      disabled: !("id" in record), // Column configuration not to be checked
    }),
  };
  const deleteSelected = () => {
    const idsToDelete = logsContext.selected.map((key: Key) => {
      const log = logsContext.logs.find((log) => log.key === key);
      if (log !== undefined) {
        return log.id;
      }
    });
    axios
      .post(`${API_URL}/api/data/delete_logs/`, idsToDelete)
      .then(() => {
        logsContext.fetchLogs();
        logsContext.setSelected([]);
      })
      .catch(() => {});
  };

  return (
    <Form form={logsContext.editForm} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={logsContext.logs}
        columns={mergedColumns}
        rowClassName="editable-row"
        rowSelection={rowSelection}
        pagination={{
          current: logsContext.currentTablePage,
          defaultPageSize: logsContext.pageSize,
          // onChange: cancel,
          onChange: (page: number, pageSize: number) => {
            logsContext.setCurrentTablePage(page);
            logsContext.setPageSize(pageSize);
          },
        }}
        scroll={{ x: 2900 }}
        title={() => {
          return (
            <Row>
              <Col flex={98}>
                <Typography.Title
                  style={{ marginTop: 5, marginBottom: 0 }}
                  level={5}
                >
                  Failed entries pending rectification:{" "}
                  {
                    <Typography.Text type={"secondary"}>
                      ({number_of_logs})
                    </Typography.Text>
                  }
                </Typography.Title>
                <Typography.Title
                  style={{ marginTop: 5, marginBottom: 0 }}
                  level={5}
                >
                  Total registered entries:{" "}
                  {
                    <Typography.Text type={"secondary"}>
                      ({logsContext.noOfEntries})
                    </Typography.Text>
                  }
                </Typography.Title>
                {logsContext.selected.length > 1 && (
                  <Popconfirm
                    onConfirm={deleteSelected}
                    title={"Sure to delete all selected logs?"}
                  >
                    <Button
                      type={"primary"}
                      danger
                      style={{ display: "block", width: 165, marginTop: 7 }}
                    >
                      <DeleteOutlined style={{ marginRight: 5 }} />
                      Delete selected
                    </Button>
                  </Popconfirm>
                )}
              </Col>
              <Col flex={2}>
                <Button
                  type={"primary"}
                  disabled={logsContext.logs.length === 0}
                  style={{ display: "block", width: 140 }}
                >
                  <CSVLink
                    filename={`logs.csv`}
                    data={logsContext.logs}
                    className="btn btn-primary"
                  >
                    <DownloadOutlined style={{ marginRight: 10 }} />
                    Download
                  </CSVLink>
                </Button>
              </Col>
            </Row>
          );
        }}
      />
    </Form>
  );
};

export default LogsTable;
