// import React, { createContext } from "react";
// import { Navigate, Route, Routes } from "react-router-dom";
// import Login from "../../views/Auth/Login";
// import HomePage from "../../App/HomePage";
//
import React, { createContext } from "react";

type authObjType = {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
};

export const AuthContext = createContext<authObjType | undefined>(undefined);
//
// export function LoginRoute() {
//   return (
//     <Routes>
//       <Route path="/login" element={<Login />} />
//       <Route path="*" element={<Navigate to="login" replace />}></Route>
//     </Routes>
//   );
// }
//
// export function AuthenticatedRoutes() {
//   return (
//     <Routes>
//       <Route path="/home" element={<HomePage />}></Route>
//       <Route path="*" element={<Navigate to="/home" replace />}></Route>
//     </Routes>
//   );
// }

export default function Dummy() {
  console.log("pass");
}
// const [token, setToken] = useState("");
// const authObj = {
//   token: token,
//
// }
// export const AuthContext = createContext(useState(""));
