let BASE_URL: string;
let API_URL: string;
// let SOCKET_URL: string;
// let MEDIA_URL: string;

switch (process.env.REACT_APP_GOAL) {
  case "production": {
    BASE_URL = "api.brd.traderion.com";
    API_URL = `https://${BASE_URL}`;
    // SOCKET_URL = `wss://${BASE_URL}/api`;
    // MEDIA_URL = "https://pm.api.s3.amazonaws.com/media";
    break;
  }
  default: {
    BASE_URL = "localhost:8080";
    API_URL = `http://${BASE_URL}`;
    // SOCKET_URL = `ws://${BASE_URL}/api`;
    // MEDIA_URL = `http://${BASE_URL}`;
  }
}

export { BASE_URL, API_URL };
// , SOCKET_URL, MEDIA_URL };
