import { ColumnsType } from "antd/es/table";

export type TrainingsType = {
  then_marca: string;
  regiune: string;
  agentie: string;
  cod_cc: string;
  pozitie: string;
  an: string;
  tip_formare: string;
  track: string;
  denumire_track: string;
  progres: string;
  timp_online: string;
  status: string;
  activ: string;
  data_certificare: string;
  serie_certificat: string;
};

export type TraineeType = {
  marca: string;
  nume: string;
  prenume: string;
  email: string;
};

export const traineeInitialState = {
  marca: "",
  nume: "",
  prenume: "",
  email: "",
};

export const trainingsInitialState = [
  {
    then_marca: "",
    regiune: "",
    agentie: "",
    cod_cc: "",
    pozitie: "",
    an: "",
    tip_formare: "",
    track: "",
    denumire_track: "",
    progres: "",
    timp_online: "",
    status: "",
    activ: "",
    data_certificare: "",
    serie_certificat: "",
  },
];

const renderIfNull = (value: string | number) => {
  if (value === null) {
    return "";
  } else {
    return value;
  }
};

export const trainingsColumns: ColumnsType<TrainingsType> = [
  {
    title: "An",
    dataIndex: "an",
    key: "an",
    fixed: "left",
    width: 70,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Track",
    dataIndex: "track",
    key: "track",
    fixed: "left",
    width: 100,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Tip Formare",
    dataIndex: "tip_formare",
    key: "tip_formare",
    fixed: "left",
    width: 90,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Denumire Track",
    dataIndex: "denumire_track",
    key: "denumire_track",
    fixed: "left",
    width: 160,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Marca",
    dataIndex: "then_marca",
    key: "then_marca",
    width: 90,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "regiune",
    dataIndex: "regiune",
    key: "regiune",
    width: 140,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "agentie",
    dataIndex: "agentie",
    key: "agentie",
    width: 120,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Cod CC",
    dataIndex: "cod_cc",
    key: "cod_cc",
    width: 70,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Pozitie",
    dataIndex: "pozitie",
    key: "pozitie",
    width: 140,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Progres",
    dataIndex: "progres",
    key: "progres",
    width: 90,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Timp Online",
    dataIndex: "timp_online",
    key: "timp_online",
    width: 80,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "status",
    dataIndex: "status",
    key: "status",
    width: 110,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Activ",
    dataIndex: "activ",
    key: "activ",
    width: 70,
    render: (value) => {
      return renderIfNull(value);
    },
  },
  {
    title: "Data Certificare",
    dataIndex: "data_certificare",
    key: "data_certificare",
    fixed: "right",
    render: (value) => {
      if (value !== null) {
        return value.split("T")[0];
      }
      return "";
    },
    width: 110,
  },
  {
    title: "Serie Certificat",
    dataIndex: "serie_certificat",
    key: "serie_certificat",
    fixed: "right",
    width: 140,
    render: (value) => {
      return renderIfNull(value);
    },
  },
];
