import React, { useState } from "react";
import "./App.css";
import Router from "./Router";
import { AuthContext } from "../State/AuthContext/AuthContext";
import { notification } from "antd";
import { NotificationContext } from "../State/NotificationsContext/NotificationContext";

function App() {
  const [token, setToken] = useState(localStorage.getItem("access_token"));
  const [api, contextHolder] = notification.useNotification();

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      <NotificationContext.Provider value={{ api: api }}>
        {contextHolder}
        <Router />;
      </NotificationContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
