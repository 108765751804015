import AdminHeader from "./AdminHeader";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountsTab from "./AccountsTab/AccountsTab";
import DataTab from "./DataTab/DataTab";
import React, { useContext, useEffect } from "react";
import TraineeSearch from "../UserViews/TraineeSearch/TraineeSearch";
import { DataTabContext } from "../../State/AdminState/DataTab/DataTabContext";

export function AdminIndex() {
  const dataTabContext = useContext(DataTabContext);

  useEffect(() => {
    dataTabContext.fetchLogs();
  }, [dataTabContext.uploading]);

  return (
    <>
      <AdminHeader />
      <Routes>
        <Route path="/admin/accounts" element={<AccountsTab />} />
        <Route path="/admin/data" element={<DataTab />} />
        <Route path="/admin/trainee_search" element={<TraineeSearch />} />
        <Route
          path="*"
          element={<Navigate to="/admin/accounts" replace />}
        ></Route>
      </Routes>
    </>
  );
}
