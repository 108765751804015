import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Table,
  Typography,
  Radio,
  RadioChangeEvent,
  Form,
} from "antd";
import React, { useContext } from "react";
import axios from "axios";
import { UserOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import {
  traineeInitialState,
  trainingsColumns,
  trainingsInitialState,
} from "../../../State/SharedState/TraineeSearchTab/Columns&Types&InitialState";
import { TraineeSearchContext } from "../../../State/SharedState/TraineeSearchTab/TraineeSearchContext";
import { useForm } from "antd/es/form/Form";
import { NotificationContext } from "../../../State/NotificationsContext/NotificationContext";
import { API_URL } from "../../../Settings";

export default function TraineeSearch() {
  const notificationContext = useContext(NotificationContext);
  const [formMarca] = useForm();
  const [formEmail] = useForm();
  const [formName] = useForm();

  const context = useContext(TraineeSearchContext);
  formMarca.setFieldValue("marca", context.byMarca.searchTerm);
  formEmail.setFieldValue("email", context.byEmail.searchTerm);
  formName.setFieldValue("nume", context.byName.searchTerm.nume);
  formName.setFieldValue("prenume", context.byName.searchTerm.prenume);

  function failureNotification(err_message: string) {
    if (err_message == "Trainee matching query does not exist.") {
      err_message = "Specified trainee doesn't exist.";
    }
    notificationContext.api.error({
      message: `Data retrieval failed!`,
      description: err_message,
      placement: "topRight",
      duration: 3,
    });
  }

  async function onFormSubmitByMarca(marca: string) {
    axios
      .post(`${API_URL}/api/data/get_trainee_by_marca/`, {
        marca: marca,
      })
      .then((response) => {
        // TODO: this is weird, setting state and then setting fields
        console.log("success");
        console.log(response.data);
        context.byMarca.setTrainee(response.data["trainee"]);
        context.byMarca.setTrainings(response.data["trainings"]);
      })
      .catch((response) => {
        context.byMarca.setTrainee(traineeInitialState);
        context.byMarca.setTrainings(trainingsInitialState);
        failureNotification(response.response.data);

        console.log(response.response.data);
      });
  }
  async function onFormSubmitByEmail(email: string) {
    axios
      .post(`${API_URL}/api/data/get_trainee_by_email/`, {
        email: email,
      })
      .then((response) => {
        console.log(response.data);
        // TODO: this is weird, setting state and then setting fields
        // setTrainee(response.data["trainee"]);
        context.byEmail.setTrainee(response.data["trainee"]);
        context.byEmail.setTrainings(response.data["trainings"]);
      })
      .catch((response) => {
        context.byEmail.setTrainee(traineeInitialState);
        context.byEmail.setTrainings(trainingsInitialState);
        failureNotification(response.response.data);

        console.log(response.response.data);
      });
  }
  async function onFormSubmitByName(nume: string, prenume: string) {
    axios
      .post(`${API_URL}/api/data/get_trainee_by_name/`, {
        nume: nume,
        prenume: prenume,
      })
      .then((response) => {
        console.log(response.data);
        // TODO: this is weird, setting state and then setting fields
        // setTrainee(response.data["trainee"]);
        context.byName.setTrainee(response.data["trainee"]);
        context.byName.setTrainings(response.data["trainings"]);
      })
      .catch((response) => {
        context.byName.setTrainee(traineeInitialState);
        context.byName.setTrainings(trainingsInitialState);
        failureNotification(response.response.data);

        console.log(response.response.data);
      });
  }

  let usedTrainings = context.byMarca.trainings;
  let usedTrainee = context.byMarca.trainee;

  if (context.searchBy === "email") {
    usedTrainings = context.byEmail.trainings;
    usedTrainee = context.byEmail.trainee;
  }
  if (context.searchBy === "name") {
    usedTrainings = context.byName.trainings;
    usedTrainee = context.byName.trainee;
  }

  const searchByMarca = () => {
    return (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        // initialValues={{ remember: true }}
        onFinish={() => {
          onFormSubmitByMarca(context.byMarca.searchTerm);
        }}
        autoComplete="off"
        form={formMarca}
      >
        <Form.Item
          label="Marca"
          name="marca"
          rules={[{ required: true, message: "Please input user's marca." }]}
        >
          <Input
            onChange={(event) => {
              if (event.target.value === "") {
                context.byMarca.setTrainee(traineeInitialState);
                context.byMarca.setTrainings(trainingsInitialState);
              }
              context.byMarca.setSearchTerm(event.target.value);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      // <>
      //   <Row>
      //     <Input
      //       style={{ marginBottom: 20, width: "80%" }}
      //
      //       value={context.byMarca.searchTerm}
      //       onChange={(event) => {
      //         if (event.target.value === "") {
      //           context.byMarca.setTrainee(traineeInitialState);
      //           context.byMarca.setTrainings(trainingsInitialState);
      //         }
      //         context.byMarca.setSearchTerm(event.target.value);
      //       }}
      //       onPressEnter={() => {
      //         onSearchByMarca(context.byMarca.searchTerm);
      //       }}
      //       allowClear
      //     />
      //   </Row>
      //   <Row>
      //     <Button
      //       style={{ width: 93 }}
      //       type="primary"
      //       htmlType={"submit"}
      //       onClick={() => {
      //         onSearchByMarca(context.byMarca.searchTerm);
      //       }}
      //     >
      //       Search
      //     </Button>
      //   </Row>
      // </>
    );
  };
  const searchByEmail = () => {
    return (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        // initialValues={{ remember: true }}
        onFinish={() => {
          onFormSubmitByEmail(context.byEmail.searchTerm);
        }}
        autoComplete="off"
        form={formEmail}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input user's email." }]}
        >
          <Input
            onChange={(event) => {
              if (event.target.value === "") {
                context.byEmail.setTrainee(traineeInitialState);
                context.byEmail.setTrainings(trainingsInitialState);
              }
              context.byEmail.setSearchTerm(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      // <>
      //   <Row>
      //     <Input
      //       style={{ marginBottom: 20, width: "80%" }}
      //       addonBefore={
      //         <p style={{ margin: 0, width: 70, textAlign: "right" }}>Email:</p>
      //       }
      //       value={context.byEmail.searchTerm}
      //       onChange={(event) => {
      //         if (event.target.value === "") {
      //           context.byEmail.setTrainee(traineeInitialState);
      //           context.byEmail.setTrainings(trainingsInitialState);
      //         }
      //         context.byEmail.setSearchTerm(event.target.value);
      //       }}
      //       onPressEnter={() => {
      //         onSearchByEmail(context.byEmail.searchTerm);
      //       }}
      //       allowClear
      //     />
      //   </Row>
      //   <Row>
      //     <Button
      //       style={{ width: 93 }}
      //       type="primary"
      //       onClick={() => {
      //         onSearchByEmail(context.byEmail.searchTerm);
      //       }}
      //     >
      //       Search
      //     </Button>
      //   </Row>
      // </>
    );
  };

  const searchByName = () => {
    return (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        // initialValues={{ remember: true }}
        onFinish={() => {
          onFormSubmitByName(
            context.byName.searchTerm.nume,
            context.byName.searchTerm.prenume
          );
        }}
        autoComplete="off"
        form={formName}
      >
        <Form.Item
          label="Nume"
          name="nume"
          rules={[{ required: true, message: "Please input user's name." }]}
        >
          <Input
            onChange={(event) => {
              if (event.target.value === "") {
                context.byName.setTrainee(traineeInitialState);
                context.byName.setTrainings(trainingsInitialState);
              }
              const new_full_name = {
                ...context.byName.searchTerm,
                nume: event.target.value,
              };
              context.byName.setSearchTerm(new_full_name);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Prenume"
          name="prenume"
          rules={[{ required: true, message: "Please input user's prenume." }]}
        >
          <Input
            onChange={(event) => {
              if (event.target.value === "") {
                context.byName.setTrainee(traineeInitialState);
                context.byName.setTrainings(trainingsInitialState);
              }
              const new_full_name = {
                ...context.byName.searchTerm,
                prenume: event.target.value,
              };
              context.byName.setSearchTerm(new_full_name);
            }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div style={{ marginLeft: 50, marginRight: 50 }}>
      <Row>
        {/*========== SELECT CARD ===========*/}
        {/*<div style={{ width: "40%", display: "inline-block", minWidth: 280 }}>*/}
        {/*<div style={{ display: "flex" }}>*/}
        <Col span={7} style={{ minWidth: 280 }}>
          <Card
            style={{
              marginRight: 20,
              marginBottom: 20,
              boxShadow: "0 3px 24px 0 rgba(0, 0, 0, 0.03)",
              // maxWidth: 600,
              // minWidth: 410,
            }}
          >
            <Typography.Title
              level={5}
              style={{
                marginTop: 0,
                marginRight: 12,
                marginBottom: 0,
                display: "inline-block",
              }}
            >
              Search trainee by:
            </Typography.Title>
            <Radio.Group
              value={context.searchBy}
              onChange={(e: RadioChangeEvent) => {
                context.setSearchBy(e.target.value);
              }}
            >
              <Radio.Button value="marca">Marca</Radio.Button>
              <Radio.Button value="email">Email</Radio.Button>
              <Radio.Button value="name">Name</Radio.Button>
            </Radio.Group>
            <Divider style={{ marginTop: 20 }} type={"horizontal"}></Divider>
            {context.searchBy === "marca" && searchByMarca()}
            {context.searchBy === "email" && searchByEmail()}
            {context.searchBy === "name" && searchByName()}
          </Card>
        </Col>
        {/*========== TRAINEE DETAILS CARD ===========*/}
        <Col span={17}>
          <Card
            title={"Trainee:"}
            style={{
              minWidth: 700,
              marginBottom: 20,
              boxShadow: "0 3px 24px 0 rgba(0, 0, 0, 0.03)",
            }}
          >
            <Row>
              {/*<Col flex={10}>*/}
              <Col span={2}>
                <Avatar
                  style={{ marginTop: 10 }}
                  size={64}
                  icon={<UserOutlined />}
                />
              </Col>
              {/*<Col flex={45}>*/}
              <Col span={11}>
                <div style={{ width: "90%", marginLeft: "5%" }}>
                  <Input
                    style={{ marginBottom: 20 }}
                    addonBefore={
                      <p style={{ margin: 0, width: 70, textAlign: "right" }}>
                        Nume:
                      </p>
                    }
                    value={usedTrainee.nume}
                  />
                  <Input
                    addonBefore={
                      <p style={{ margin: 0, width: 70, textAlign: "right" }}>
                        Prenume:
                      </p>
                    }
                    value={usedTrainee.prenume}
                  />
                </div>
              </Col>
              <Col span={11}>
                <div style={{ width: "90%", marginLeft: "5%" }}>
                  <Input
                    style={{ marginBottom: 20 }}
                    addonBefore={
                      <p style={{ margin: 0, width: 70, textAlign: "right" }}>
                        Marca:
                      </p>
                    }
                    value={usedTrainee.marca}
                  />
                  <Input
                    addonBefore={
                      <p style={{ margin: 0, width: 70, textAlign: "right" }}>
                        Email:
                      </p>
                    }
                    value={usedTrainee.email}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/*========== TRAININGS TABLE ===========*/}
      <Row>
        <Table
          style={{
            boxShadow: "0 3px 24px 0 rgba(0, 0, 0, 0.03)",
          }}
          scroll={{ x: 2000 }}
          columns={trainingsColumns}
          dataSource={usedTrainings}
          title={() => {
            return (
              <Row>
                <Col flex={98}>
                  <Typography.Title
                    style={{ marginTop: 0, marginBottom: 0 }}
                    level={5}
                  >
                    Trainings:
                  </Typography.Title>
                </Col>
                <Col flex={2}>
                  <Button
                    // disabled={usedForm.getFieldValue("nume") == undefined}
                    disabled={true}
                  >
                    <CSVLink
                      filename={`trainings.csv`}
                      data={usedTrainings}
                      className="btn btn-primary"
                    >
                      Download
                    </CSVLink>
                  </Button>
                </Col>
              </Row>
            );
          }}
          bordered
        />
      </Row>
    </div>
  );
}
