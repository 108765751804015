import React, { useContext } from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload, Progress } from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";
import axios from "axios";
import { DataTabContext } from "../../../State/AdminState/DataTab/DataTabContext";
// import { initialLogsState } from "../../../State/AdminState/DataTab/TypesInitials&Columns";
import { NotificationContext } from "../../../State/NotificationsContext/NotificationContext";
import { API_URL } from "../../../Settings";
const { Dragger } = Upload;

const UploadComponent: React.FC = () => {
  const context = useContext(DataTabContext);
  const notificationContext = useContext(NotificationContext);

  function successNotification(number_of_logs: number) {
    // TODO: DRY this up
    let description1 = "";
    let description2 = "";
    if (number_of_logs == 1) {
      description1 = `There is now a total of ${number_of_logs} logs.`;
      description2 = "Please resolve the conflict.";
    }
    if (number_of_logs > 1) {
      description1 = `There is now a total of ${number_of_logs} logs.`;
      description2 = "Please resolve the conflicts.";
    }
    notificationContext.api.success({
      message: `Data uploaded!`,
      description: (
        <>
          {description1} <br />
          <br />
          {description2}
        </>
      ),
      placement: "topRight",
      duration: 0,
    });
  }
  function failureNotification(response: { error: string; header: string }) {
    notificationContext.api.error({
      message: response.error,
      // description: 'Marca,Email,Nume,Prenume,Regiune,Agentie,Cod_CC,Pozitie,An,Tip_formare,Track,Denumire_track,Progres,Timp_online,Status,Activ,Data_certificare,Serie_certificat'
      description: `Use: ${response.header} | Don't use commas in date fields!`,
      placement: "topRight",
      duration: 0,
    });
  }

  const getProgress = async (celery_task_id: string) => {
    setTimeout(() => {
      axios
        .get(`${API_URL}/celery-progress/${celery_task_id}/`)
        .then((response) => {
          console.log(response);
          context.setProgress(Math.round(response.data.progress.percent));
          context.setCurrentlyProcessed(response.data.progress.description);
          if (response.data.progress.percent != 100) {
            getProgress(celery_task_id);
          }
          if (response.data.progress.percent == 100) {
            context.setUploading(false);
            context.setProgress(0);
            successNotification(response.data.result);
          }
        });
    }, 100);
  };

  const uploadRequest = async (options: UploadRequestOption) => {
    context.setUploading(true);
    const formData = new FormData();
    formData.append("file", options.file);
    axios
      .post(`${API_URL}/api/data/upload_csv/`, formData)
      .then((response) => {
        const celery_task_id = response.data;
        console.log(celery_task_id);
        getProgress(celery_task_id);
        // logsContext.setLogs(response.data);
        // context.setLogs(initialLogsState);
        // successNotification(Object.keys(response.data).length);
      })
      .catch((response) => {
        failureNotification(response.response.data);
        context.setUploading(false);
      });
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    // action: "http://localhost:8080/api/data/upload_csv/",
    // method: "POST",
    // headers: { token: "" },
    customRequest: uploadRequest,
    onChange(info) {
      // setLoading(!loading);
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // const deleteRequest = () => {
  //   axios.post(`${API_URL}/api/data/delete_data/`);
  // };

  return (
    <>
      <Dragger
        {...uploadProps}
        showUploadList={false}
        // onDrop={() => setLoading(true)}
      >
        <p className="ant-upload-drag-icon">
          {context.uploading ? (
            <Progress
              type="circle"
              percent={context.progress}
              strokeColor={{
                "0%": "#3343b6",
                "100%": "#cc46c6",
              }}
            />
          ) : (
            <InboxOutlined />
          )}
        </p>
        <p className="ant-upload-text">
          {context.uploading
            ? "Processing, please do not refresh."
            : "Click or drag file to this area to upload"}
        </p>
        <p className="ant-upload-hint">
          {context.uploading
            ? `${context.currentlyProcessed}`
            : "Support for csv format bulk upload. Strictly prohibited from uploading company data or other banned files."}
        </p>
      </Dragger>
      {/*<Button danger onClick={() => deleteRequest()}>*/}
      {/*  Delete db data*/}
      {/*</Button>*/}
    </>
  );
};

export default UploadComponent;
